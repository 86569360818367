import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero";

class IndexPage extends React.Component {

  render() {
    return (
      <Layout>
      <SEO title="Home" />
      <Hero />
      {/* <Hero data={this.props.data} /> */}
    </Layout>
    )
  }
}

// export const query = graphql`
//   {
//     allNodeBlog(limit: 6, sort: {fields: created, order: DESC}) {
//       edges {
//         node {
//           id
//           path {
//             alias
//           }
//           title
//           created(formatString: "MMMM DD, YYYY")
//           field_featured
//         }
//       }
//     }
//   }
// `

export default IndexPage
